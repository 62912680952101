import { defu } from 'defu'
import { stringify } from 'qs'
import type { UseFetchOptions } from 'nuxt/app'

export const useApiPublic: typeof useFetch = <T>(url: MaybeRefOrGetter<string>, options: UseFetchOptions<T> = {}) => {
  const config = useRuntimeConfig()

  const defaults: UseFetchOptions<T> = {
    baseURL: `${config.public.apiBaseUrl}/api/common`,
    key: toValue(url),
    onRequest({ options }) {
      if (options.params) {
        const convertedOptions = new URLSearchParams(stringify(options.params))

        options.params = Object.fromEntries(convertedOptions)
      }
    },
  }

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults)

  return useFetch(url, params)
}
